import { useNavigate } from "react-router-dom";
import {
  Box,
  CardHeader,
  Skeleton,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import ColoredCard from "./ColoredCard";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Divider from "@mui/material/Divider";

const FittingCard = ({ rec, postponed = false }) => {
  const navigate = useNavigate();

  return (
    <ColoredCard status={rec.state}>
      <CardHeader
        sx={{ paddingBottom: 0 }}
        title={
          <Typography variant="h5">
            {rec.name_last}, {rec.name_first}
          </Typography>
        }
        subheader={
          <Typography variant="subtitle2">
            {rec.addr_city}, {rec.addr_state}
          </Typography>
        }
        action={
          <Stack direction="row" spacing={1}>
            <IconButton
              size="small"
              onClick={() => navigate(`/service/${rec.treat_id}`)}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </Stack>
        }
      />
      <Typography sx={{ paddingLeft: 2 }} variant="overline">
        {postponed ? "POSTPONED" : rec.numberText}
      </Typography>
    </ColoredCard>
  );
};

function ServiceList({ data, tab }) {
  const rowSkeletons = 10;

  const rows = [];

  if (data === null) {
    for (let index = 0; index < rowSkeletons; index++) {
      rows.push(<Skeleton variant="rectangular" height={100} key={index} />);
    }
  } else {
    const show = data[tab];

    const sortedData = [...show].sort(function (a, b) {
      return new Date(a.date) - new Date(b.date);
    });

    sortedData.forEach((e) => {
      const timeDiff = new Date(e.date).getTime() - new Date().getTime();
      const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

      e.number = Math.abs(dayDiff);
      switch (dayDiff) {
        case 0:
          e.numberText = "Today";
          break;
        case 1:
          e.numberText = "Tomorrow";
          break;
        case -1:
          e.numberText = "Yesterday";
          break;
        default:
          if (dayDiff < 0) e.numberText = e.number + " Days Ago";
          else e.numberText = e.number + " Days";
      }

      if (tab === "pickup") {
        if (dayDiff < -15) {
          e.state = "error";
        } else if (dayDiff < -7) {
          e.state = "warning";
        } else if (dayDiff < 1) {
          e.state = "success";
        } else {
          e.state = "info";
        }
      } else {
        if (dayDiff < 0) e.state = "error";
        else if (dayDiff < 5) e.state = "warning";
        else if (dayDiff < 7) e.state = "success";
        else e.state = "info";
      }
    });

    const postData = sortedData.filter((rec) => rec.status === "postponed");

    postData.forEach((rec) => {
      rows.push(<FittingCard rec={rec} key={rec.treat_id} postponed />);
    });
    const hasPostponed = !!postData.length;
    if (hasPostponed) {
      rows.push(
        <Box sx={{ py: 1 }}>
          <Divider />
        </Box>
      );
    }

    sortedData
      .filter((rec) => rec.status !== "postponed")
      .forEach((rec) => {
        rows.push(<FittingCard rec={rec} key={rec.treat_id} />);
      });
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Stack spacing={1}>{rows}</Stack>
    </Box>
  );
}

export default ServiceList;
